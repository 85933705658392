'use strict';

// если в правах есть такая запись
function isAllow(value)
{   

    let rights = localStorage.getItem('rights');
    if(rights == null) return false;
    let arr = rights.split(',');
    for(var i in arr)
        if(arr[i].trim() == value) return true;
    return false;
}

export default isAllow;