/*
  Бланк рецепта
*/

import React, { createRef, useEffect } from 'react';
import axios_sender from '../modules/axios_sender';

import './RecipeBlank.css';
import ClientInfo from './ClientInfo/ClientInfo.js';
import MP from './MP/MP.js';
import log_array from '../modules/log_array.js';


function RecipeBlank() {

  const refCI = createRef();
  const refMP = createRef();

    
  return (
    <div className="container-fluid row">

      <div className="col-4">
        <ClientInfo ref={refCI} />
        <div className="saveRecipe row">
          <div className="col-3">
            <button type="button" className="btn btn-outline-danger" onClick={ ClearForm }>Очистить</button>
          </div>
          <div className="col-1"></div>
          <div className="col-8">
            <button type="button" className="btn btn-primary" onClick={ SaveRecipe }>Сохранить рецепт</button>
          </div>        
        </div>
      </div>

      <div className="col-8">
        <MP ref={refMP} buttonAdd="true"/>      
      </div>
    </div> 
  );

  //#region функция очистки формы
    function ClearForm()
    {
      //if(!window.confirm("Форма рецепта будет полностью очищена.\nУверены?")) return;
      refCI.current.ClearForm();
      refMP.current.ClearForm();
    }
  //#endregion

  //#region функция сохранения рецепта
    function SaveRecipe()
    {
      let ci = refCI.current.GetRecipeData();
      let mp_arr = refMP.current.GetRecipeData();      

      let cl_subject = "";
      if(ci.client_default_address) cl_subject = "Челябинская область, г.Снежинск";

      let clientInfo = {
        client_name_1: ci.client_name_1,
        client_name_2: ci.client_name_2,
        client_name_3: ci.client_name_3,
        client_address: ci.client_address,
        client_town: ci.client_town,
        client_street: ci.client_street,        
        client_house: ci.client_house,
        client_flat: ci.client_flat,
        client_subject: cl_subject,
        animal_type: ci.animal_type,
        animal_sex: ci.animal_sex,
        animal_age: ci.animal_age,
        animal_weight: ci.animal_weight,
        animal_name: ci.animal_name,
        animal_qty: ci.animal_qty,
        recipe_date: ci.recipe_date,
      }

      var mp = [];
      for(var i in mp_arr) mp.push({id_drug: mp_arr[i].id_drug, data: mp_arr[i].data});
      

      if(mp.length == 0)
      {
        alert("Ни один препарат не добавлен в рецепт. Рецепт не сохранен.");
        return;
      }

      // отправим данные для сохранения рецепта
      axios_sender({form: 'save_recipe', ci:clientInfo, mp: mp}, SaveRecipeSuccess);      
    }

    function SaveRecipeSuccess(data)
    {

console.log("ddddd"+data);
      if(data == null || data < 0)
      {
        alert("Ошибка сохранения рецепта");
        return;
      }
      

      window.location.assign("/look/"+data);
      //ClearForm();
    }

  //#region 

}

export default RecipeBlank;


