'use strict';

// из строки получаем число
function str_to_num(src)
{    
    let str = '';
    let is_delitel = true;
    for(var i = 0; i<src.length; i++)
    {
        if(src[i] >= '0' && src[i] <= '9') str += src[i];
        else if((src[i] == ',' || src[i] == '.') && is_delitel) { str += '.'; is_delitel = false; }
    }
    return str;
}

export default str_to_num;