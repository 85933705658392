/*
  Один рецепт
*/

import React, {useState, useEffect} from 'react';
import axios_sender from '../../modules/axios_sender';
import { NavLink } from 'react-router-dom';


import getRecipeNum from '../../modules/recipe_get_num.js'

import './OneRecipe.css';

function OneRecipe(props) {


  const [drug_list, setDrugList] = useState('');    // список препаратов в рецепте

  const [state_show_drugs, setShowDrugs] = useState(false);

  const reduct_href = '/look/' + props.recipe_info.id;
  const repay_href = '/repay/' + props.recipe_info.id;


//#region функции задания стиля для типов
  function cn_animal_sex (animal_sex)
  {
    if(animal_sex == "М") return "sexM";
    return "sexW";
  }
  function cn_animal_type (animal_type)
  {
    switch(animal_type)
    {
      case "Кошка": return "cat";
      case "Собака": return "dog";
    }
    return "typeNone";
  }
  //#endregion
  
//#region   пользователь желает посмотреть/скрыть лекарства в рецепте

  function ToggleDrugs()
  {
    if(!state_show_drugs)
    {
        ShowDrugs();
        setShowDrugs(true);
    }
    else {
      ClearDrugs();
      setShowDrugs(false);
    }
  }

  function ShowDrugs()
  {         
    axios_sender({form: 'recipe_get_drugs', id: props.recipe_info.id}, ShowDrugsSuccess);
  }
  function ShowDrugsSuccess(data)
  {
    let list = [];
    for(var i in data)
    {
      let s = data[i];
      let key = 'd_' + s.num;
      list.push(
          <div className="container-fluid drug" key={key}>
            <div className="row">
              <div className="col-2"><span>№ {getRecipeNum(s.num)}</span></div>
              <div className="col-2"><span>{s.drug}</span></div>
              <div className="col-3">дозировка: <span>{s.dosage} {s.unit_meas}</span></div>
              <div className="col-2">доза: <span>{s.dose}</span></div>              
            </div>
          </div>
      );
    }
    ToggleDrugs = () => ClearDrugs();
    setDrugList(list);
  }

  function ClearDrugs()
  {
    ToggleDrugs = () => ShowDrugs();
    setDrugList("");
  }
//#endregion
  
  
  
  return (
    <div className="mb-2 OneRecipe border border-1 container-fluid" onClick={ToggleDrugs}>
          <div className="capt row">
              <div className="id col-1">ID: &nbsp;<span>{props.recipe_info.id}</span></div>
              <div className="date col-2">{props.recipe_info.recipe_datetime}</div>
              <div className="doctor col-7">врач:<span className="doctor">{props.recipe_info.doctor}</span></div>
              <div className="col-1">
                  <NavLink to={reduct_href}><div className="Look" title="Просмотреть рецепт"></div></NavLink>                  
              </div>
              <div className="col-1">
                <NavLink to={repay_href}><div className="Repay" title="Погасить рецепт"></div></NavLink>
              </div>
          </div>
          <div className="row capt">
            <div className="col-2 animal">тип:<span className={cn_animal_type(props.recipe_info.animal_type)}>{props.recipe_info.animal_type}</span></div>
            <div className="col-3 animal">имя:<span>{props.recipe_info.animal_name}</span></div>
            <div className="col-2 animal"><span>возвр.{props.recipe_info.animal_age}</span></div>
            <div className="col-2 animal">пол:<span className={cn_animal_sex(props.recipe_info.animal_sex)}>{props.recipe_info.animal_sex}</span></div>
            <div className="col-2 animal">вес:<span>{props.recipe_info.animal_weight} кг.</span></div>
          </div>
          <div className="row">
            <div className="col-6 client">клиент:<span>{props.recipe_info.client_name1} {props.recipe_info.client_name2} {props.recipe_info.client_name3}</span></div>
            <div className="col-4 client">адрес:<span>{props.recipe_info.client_address}</span></div>
          </div>

          <div>{drug_list}</div>
    </div>
  );

}

export default OneRecipe;

