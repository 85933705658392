/*
  Инфоромация о препаратах в рецепте
*/

import React, { useEffect, useState } from 'react';
import Select from 'react-select'

import axios_sender from '../../modules/axios_sender';
import getRecipeNum from '../../modules/recipe_get_num.js'

import './MP.css';
import log_array from '../../modules/log_array.js';
import ToFloat from '../../modules/my_float.js'

class MP extends React.Component {
  constructor(props, ref) {
    super(props, ref);  

    //this.FillDrugAftenList = this.FillDrugAftenList.bind(this);     // привязка this к событию
    this.AddNewMP = this.AddNewMP.bind(this);     // привязка this к событию
    this.GetFromMPArray = this.GetFromMPArray.bind(this);     // привязка this к событию
    
    
    this.state = {
      mp_list : 0,// список препаратов в выпадающем списке
      recipe_mp_list: <strong className="Empty">Препаратов в рецепте нет</strong>,       // список препаратов в рецепте
      // списки в препарате
      recipe_list_method_application: 0,        // список метод применения
      recipe_list_dosage: 0,                    // список дозировки
      recipe_list_dose_effect: 0,               // список эффективных доз
      recipe_list_unit_meas: 0,                 // единицы измерения
      recipe_list_often_use: 0,                 // единицы частоты применения
      recipe_list_duration_use: 0,              // единицы длительности применения
      recipe_list_time_app: 0,                  // единицы времени применения
      recipe_list_release_form: 0,              // единицы формы выпуска
      description: '',                          // описание способа применения

      dose: '',                                 // доза
      mp_qty: '',                               // кол-во препарата
      qty_issue: '',                            // выдать всего

      selected_drug: 0,                         //выбранные препарат
    };


    // Массивы списков
    this.m_list_method_application = [];
    this.m_list_dosage = [];
    this.m_list_dose_effect = [];
    this.m_list_unit_meas = [];
    this.m_list_often_use = [];
    this.m_list_duration_use = [];
    this.m_list_time_app = [];
    this.m_list_release_form = [];

    this.RecipeMpList = [];     // препараты в списке
    
    
    
  }

  componentDidMount() { this.loadedObject(); }
  //componentDidUpdate() { this.loadedWin(); }

//#region   при загрузке объекта
  loadedObject() {
    // загрузим препараты
    this.LoadAllMP();
    // загрузим списки        

    this.LoadOptions("method_application", (t, list) => { t.m_list_method_application = list}, (list, obj)=>{ obj.setState( obj.recipe_list_method_application = list ); });
    //this.LoadOptions("dosage", (t, list) => { t.m_list_dosage = list}, (list, obj)=>{ obj.setState( obj.recipe_list_dosage = list ); });    
    //this.LoadOptions("dose_effect", (t, list) => { t.m_list_dose_effect = list}, (list, obj)=>{ obj.setState( obj.recipe_list_dose_effect = list ); });    
    this.LoadOptions("unit_meas", (t, list) => { t.m_list_unit_meas = list}, (list, obj)=>{ obj.setState( obj.recipe_list_unit_meas = list ); });
    this.LoadOptions("often_use", (t, list) => { t.m_list_often_use = list}, (list, obj)=>{ obj.setState( obj.recipe_list_often_use = list ); });
    this.LoadOptions("duration_use", (t, list) => { t.m_list_duration_use = list}, (list, obj)=>{ obj.setState( obj.recipe_list_duration_use = list ); });
    this.LoadOptions("time_app", (t, list) => { t.m_list_time_app = list}, (list, obj)=>{ obj.setState( obj.recipe_list_time_app = list ); });
    this.LoadOptions("release_form", (t, list) => { t.m_list_release_form = list}, (list, obj)=>{ obj.setState( obj.recipe_list_release_form = list ); });    
    if(this.props.reductRecipe > 0) this.LoadRecipeDrugs(this.props.reductRecipe);    
  }
//#endregion

//#region Загрузить все препараты  в выпадающий список
  LoadAllMP()
  {
    axios_sender({form: 'get_drugs'}, this.LoadAllMPSuccess, this);
  }
  LoadAllMPSuccess(data, obj)
  {
    let list = [];
    for(let i in data)
    {
        let s = data[i];
        let key = crypto.randomUUID();
        list.push( { value: s.ID, label: s.name, key: key });                    
    }      
    obj.setState( obj.mp_list = list );  
  }
//#endregion


// #region Функции загрузки списков

  LoadOptions(table_name, func_m, func)
  {
    axios_sender({form: 'get_list', table_name: table_name}, this.LoadOptionsSuccess, {table_name: table_name, func_m, func: func, t: this});    
  }
  LoadOptionsSuccess(data, obj)
  {       
    let arr = [];
    for(var i in data) arr.push({id: data[i].ID, name: data[i].name});
    obj.func_m(obj.t, arr);
    obj.t.RefreshOption(obj.t, arr, obj.table_name, obj.func);
  }

  RefreshOption(t, arr, table_name, func)
  {
    let list = [];
    for(let i in arr)
    {
      let s = arr[i];
      let key = table_name + "_" + s.id + crypto.randomUUID();
      list.push(
          <option key={key} value={s.id}> { s.name }</option>
      );
    }
    func(list, t);
  } 
//#endregion 

//#region   функция загрузки лекарств для рецепта (если редактирование)
  LoadRecipeDrugs(id_recipe)
  {     
    this.RecipeMpList = [];
    axios_sender({form: 'recipe_get_drugs_id', id: id_recipe}, this.LoadRecipeDrugsSuccess, this);
  }
  LoadRecipeDrugsSuccess(data, t)
  {    
      let rpm = [];
      for(let i in data)
      {            
          let s = data[i];          
          let key = crypto.randomUUID();
          let mp = ({
              key: key,
              id_drug: s.id_drug,
              data: s,
          });
          mp.data.num = getRecipeNum(mp.data.num);
          t.FillDrugAftenList(mp);
      }               
  }
//#endregion

//#region   Получение значения по ID из списка
  GetValForID(arr, id)
  {
    for(var i in arr)
      if(arr[i].id == id) return arr[i].name;
    return "";
  }

//#endregion

//#region Добавить новый препарат в рецепт

AddNewMP()
{  
  let id_drug = this.selected_drug;       // id препарата
  let animal_type = document.getElementById('animal_type').value; // id типа животного
  if(id_drug === undefined || id_drug <= 0)
  {
    alert("Препарат не выбран");
    return;
  }
  // отправим запрос на добавление препарата. У сервера будет запрошена инормация о самом частом назначении препарата
  axios_sender({form: 'recipe_add_drugs', id: id_drug, animal_type: animal_type}, this.AddNewMPSuccess, {id: id_drug, obj: this});
}

AddNewMPSuccess(data, obj)
{
  var t = obj.obj;    // это просто this 

  // проверим, может такой препарат уже есть
  for(var i in t.RecipeMpList)
    if(t.RecipeMpList[i].id_drug == obj.id)
    {
      alert("Такой препарат уже есть в рецепте");
      return;
    }
  
  let key = crypto.randomUUID();

  let id_drug = obj.id;

  // Добавим препарат в список
  let mp_one = {
    key: key,
    id_drug: id_drug,
    data: data,
  };
  
  t.FillDrugAftenList(mp_one);
}
//#endregion

// Есть одно выбранное лекарство и значения по умолчанию. Проверим, соотетствие, что такие значения в списке есть
VerifyRecipeMP(mp_one)
{
  if(!this.VerifyRecipeMPOneParam(mp_one.list_dosage, mp_one.data.dosage))
    mp_one.data.dosage = mp_one.list_dosage[0].id;

  if(!this.VerifyRecipeMPOneParam(mp_one.list_effect_dose, mp_one.data.dose_effect))
    mp_one.data.dose_effect = mp_one.list_effect_dose[0].id;
}
VerifyRecipeMPOneParam(arr, val)
{
  for(var i in arr)
    if(arr[i].id == val) return true;
  return false;
}

// добавим в массив лекарства списки дозировок и разовых доз
FillDrugAftenList(mp_one)
{
  // получим список дозировок
  axios_sender({form: 'get_mp_dosage', id_drug: mp_one.id_drug}, (data) => {
      mp_one.recipe_list_dosage = this.GetOptions(data, 'ID_dosage');
      mp_one.list_dosage = this.ConvertIDId(data, 'ID_dosage');
      axios_sender({form: 'get_mp_effect_dose', id_drug: mp_one.id_drug}, (data) => {
          mp_one.recipe_list_effect_dose = this.GetOptions(data, 'ID_dose_effect');
          mp_one.list_effect_dose = this.ConvertIDId(data, 'ID_dose_effect');

          // проверим на соответствие значения по умолчанию, что имеются такие значения в выпадающем списке
          this.VerifyRecipeMP(mp_one);
          this.RecipeMpList.push(mp_one);
          this.RefreshRecipe(this);        
      });
  });   
}
GetOptions(data, id_name)
{
  let arr = [];
  for(var i in data)
    arr.push(
      <option
        key={crypto.randomUUID()}
        value={data[i][id_name]}
        >
          {data[i].name}
      </option>
  );
  return arr;
}
ConvertIDId(data, id_name)
{
  let arr = [];
  for(var i in data)
    arr.push({id: data[i][id_name], name: data[i].name});
  return arr;
}

// найти лекарство среди выбранных по его id
GetFromMPArray(id_drug)
{
  for(var i in this.RecipeMpList)
    if(this.RecipeMpList[i].id_drug == id_drug) return this.RecipeMpList[i];
  return [];
}

// возвращает количество препарата. Принимает эффективную дозу и дозировку лекарства
CalculateDose(id_drug)
{    
  let s = this.GetFromMPArray(id_drug);
  if(s == []) return;

  // найдем значение разовой дозы
  let dose_effect = this.GetValForID(s.list_effect_dose, s.data.dose_effect); 
  // найдём значение дозировки
  let dosage = this.GetValForID(s.list_dosage, s.data.dosage);
  
  // прочитаем вес животного
  let massa = document.getElementById('animal_weight').value;
  // доза = разовая доза * вес
  let dose = (ToFloat(massa) * ToFloat(dose_effect));
  dose = parseFloat(dose);

  let mp_qty = ToFloat(dose) / ToFloat(dosage);
  mp_qty = parseFloat(mp_qty);

  if(mp_qty >= 0.8 && mp_qty <= 1.2) mp_qty = 1;
  else if(mp_qty >= 0.35 && mp_qty <= 0.7) mp_qty = "1/2";
  else if(mp_qty >= 0.1 && mp_qty <= 0.35) mp_qty = "1/4";

  s.data.dose = dose;
  s.data.mp_qty = mp_qty;

}

RefreshRecipe(obj)
{  

  let res = [];
  for(var i in obj.RecipeMpList)
  {
    let s = obj.RecipeMpList[i];
    let t = obj;

    let isButtonDel = this.ButtonDeleteDrug(t, s.id_drug);

    // регистрационное удостоверение препарата
    let dt_drug = "бессрочно";
    if(s.data.drug_reg_date != null && s.data.drug_reg_date != '0000-00-00') dt_drug = s.data.drug_reg_date;
    let drug_reg = "";
    if(s.data.drug_reg_name !== undefined && s.data.drug_reg_name != null && s.data.drug_reg_name != "") drug_reg = s.data.drug_reg_name + "  [" + dt_drug + "]";
    let drug_reg_cn = "form-control";
    let date_drug = new Date(s.data.drug_reg_date);
    if(Date.now() > date_drug) drug_reg_cn += " DateNoSuccess";
    

    if(s.data.mp_qty == null) s.data.mp_qty = "";   // потом можно будет убрать строку, когда старые рецепты состарятся
    let iii = i;

let mp = (      
  <div className="OneDrug border border-1 rounded" key={s.key}>
    <div className="row mb-2">
      <div className="col-10"><label className="DrugName">{s.data.name}</label></div>
      <div className="col-2">{isButtonDel}</div>
    </div>
    
    <div className="row mb-2">
      <div className="capt_drug"><span>Дозировка</span></div>
      <div className="val_drug">
        <select className="form-control" value={s.data.dosage}
          onChange={(e) => { s.data.dosage = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }} >
            { s.recipe_list_dosage }
        </select>
      </div>
      <div className="capt_drug"><span>Разовая доза</span></div>
      <div className="val_drug">
        <select className="form-control" value={s.data.dose_effect}
          onChange={(e) => { s.data.dose_effect = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }} >
            { s.recipe_list_effect_dose }
        </select>
      </div>
    </div>

    <div className="row mb-2">
      <div className="capt_drug"><span>Частота</span></div>
      <div className="val_drug">
        <select className="form-control" value={s.data.often_use}
          onChange={(e) => { s.data.often_use = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }} >
            { t.recipe_list_often_use }
        </select>
      </div>
      <div className="capt_drug"><span>Длительн.</span></div>
      <div className="val_drug">
        <select className="form-control" value={s.data.duration_use}
          onChange={(e) => { s.data.duration_use = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }} >
            { t.recipe_list_duration_use }
        </select>
      </div>      
    </div>

    <div className="row mb-2">
    <div className="capt_drug"><span>Время</span></div>
      <div className="val_drug">
        <select className="form-control" value={s.data.time_app}
          onChange={(e) => { s.data.time_app = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }} >
            { t.recipe_list_time_app }
        </select>
      </div>
      <div className="capt_drug"><span>Способ</span></div>
      <div className="val_drug">
        <select className="form-control" value={s.data.method_application}
          onChange={(e) => { s.data.method_application = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }} >
            { t.recipe_list_method_application }
        </select>
      </div>
    </div>

    <div className="row mb-2">
      <div className="capt_drug"><span>Форма вып.</span></div>
      <div className="val_drug">
        <select className="form-control" value={s.data.release_form}
          onChange={(e) => { s.data.release_form = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }} >
            { t.recipe_list_release_form }
        </select>
      </div>
      <div className="capt_drug"><span>Рег. уд.</span></div>
      <div className="val_drug"><input className={drug_reg_cn} value={drug_reg} readOnly/></div>
    </div>


    <div className="row mb-2">
      <div className="capt_drug"><span>Доза</span></div>
      <div className="val_drug">
      <input className="form-control Qty" value={s.data.dose}
              onChange={(e) => { s.data.dose = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }}
            />
      </div>
      <div className="capt_drug"><span>Ед.Изм.</span></div>
      <div className="val_drug row">

      <div className="col-5">
        <select className="form-control" value={s.data.unit_meas}
            onChange={(e) => { s.data.unit_meas = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }} > { t.recipe_list_unit_meas }
        </select>
        </div>
        <div className="col-5">
        <button type="button" className="DrugCalc"
        onClick={() => {
                  this.CalculateDose(s.id_drug);
                  obj.RefreshRecipe(obj);
                  obj.CreateDescription(iii, t); 
                }}></button>
        </div>
      </div>
    </div>

    <div className="row mb-2">
      <div className="capt_drug"><span>Разов. кол.</span></div>
      <div className="val_drug">
      <input className="form-control Qty" value={s.data.mp_qty}
              onChange={(e) => { s.data.mp_qty = e.target.value; obj.RefreshRecipe(obj); obj.CreateDescription(iii, t); }}
            />
      </div>
      <div className="capt_drug"><span>Выдать всего</span></div>
      <div className="val_drug">
      <input className="form-control Qty" value={s.data.qty_issue}
              onChange={(e) => { s.data.qty_issue = e.target.value; obj.RefreshRecipe(obj); }}
            />
      </div>
    </div>


    <div className="row mb-2">
      <div className="capt_drug"><span>Способ прим.</span></div>      
      <div className="col">
        <input className="form-control textDesc" value={s.data.description}
          onChange={(e) => { s.data.description = e.target.value; obj.RefreshRecipe(obj);  }}
        />
      </div>
    </div>

  </div>
    );

    res.push(mp);
  }
  obj.setState( obj.recipe_mp_list = res );    
}


  // функция составляет текст "Способ применения" в обобщенном виде
  CreateDescription(index, t)
  {
    let s = t.RecipeMpList[index].data;

    let ma = t.GetValForID(t.m_list_method_application, s.method_application);
    let release_form = t.GetValForID(t.m_list_release_form, s.release_form);
    let often_use = t.GetValForID(t.m_list_often_use, s.often_use);
    let time_app = t.GetValForID(t.m_list_time_app, s.time_app);
    let duration_use = t.GetValForID(t.m_list_duration_use, s.duration_use);

    let str = ma + ", ";
    str += "по " + s.mp_qty + " " + release_form+ " ";
    str += often_use + " " + time_app + ", ";
    str += "в течение " + duration_use;
    s.description = str;

    t.RefreshRecipe(t); 
  }


//#region Удалить препарат из списка
DeleteDrug(id)
{
  for(var i in this.RecipeMpList)
  {
    let element = this.RecipeMpList[i];
    if(element.id_drug == id)
    {
      this.RecipeMpList.splice(i, 1);
      break;
    }
  }
  this.RefreshRecipe(this);
}
//#endregion


  // очистить форму
  ClearForm()
  {    
    this.RecipeMpList = [];
    this.RefreshRecipe(this);
  }

  // возвратить данные
  GetRecipeData()
  {    
    return this.RecipeMpList;
  }


  // возвращает или не возвращает кнопку добавления рецепта
  DivAddDrug()
  {
    if(this.props.buttonAdd)
      return <div className="input-group mb-3">
              <Select className="form-control" id="drug_list" options={this.mp_list} onChange={(choice) => { this.selected_drug = choice.value; }}></Select>
              <button className="btn btn-sm btn-success" type="button" onClick={ this.AddNewMP }>Добавить препарат в рецепт</button>
            </div>    
    else return "";
  }

  //возвращает или не возвращает кнопку удаления лекарства из рецепта
  ButtonDeleteDrug(t, id_drug)
  {        
    if(t.props.buttonAdd)
      return <button className="btn btn-sm btn-outline-danger" type="button" onClick={ () => t.DeleteDrug(id_drug) }>Удалить</button>
    else return "";    
  }



  render() {      
         
    return (
      <div className="MP border border-1 rounded">
      
      { this.DivAddDrug() }
      

      <label className="form-label form-control mb-1 MP">Препараты в рецепте</label>
      <div>
        {this.recipe_mp_list}
      </div>    
    </div>
    );
  }
}
  
export default MP;    
