/*

    Шапка с названием сайта
*/

import React from 'react';

import './Header.css';



function Header() {
 
  return (
    <div className="Header">
        Сервис учёта рецептов <strong className="trough">ГАЛЕН</strong> ГАЛЯ (или просто: <span>"НАША ГАЛЯ"</span>)
    </div>    
  );
}

export default Header;
