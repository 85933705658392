'use strict';

// есть массив объектов. Возвращает новый массив в котором у .name есть (содержит) value
function filter_list(arr, value)
{    
    let val = value.toLowerCase();
    let res_arr = [];
    for(var i in arr)
    {
        if(arr[i].name.toLowerCase().includes(val)) res_arr[i] = arr[i];

    }
    return res_arr;
}

export default filter_list;