'use strict';

import axios from 'axios';
import { ajaxPath } from '../global_var.js'

// Отправка сообщения серверу
// func_is_success - вызвать функцию, если запрос прошёл успешно
// func_is_error - вызвать функцию, если запрос прошёл НЕ успешно
// data - данные для передачи серверу
// args - аргументы для передачи в функцию func_is_success
async function axios_sender(data, func_is_success, args=null, func_is_error = null)
{
  let send_data = data;
  send_data['token'] = localStorage.getItem('token');
  axios.defaults.withCredentials = true;
  await axios({
    method: 'POST',
          url: ajaxPath,          
          data: send_data,          
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: true,
        }).then(response => {
          //console.log("Success ========>", response);    
          func_is_success(response.data, args);            
      })
      .catch(error => {
          console.log("axios_sender Error ========>", error);
          if(func_is_error != null) func_is_error(error);
      }
  )  
 
}


export default axios_sender;