/*
  Списки
*/


import React , { useState, useEffect } from 'react';
import './MySelect.css';

import log_array from '../modules/log_array.js';

function MySelect(props) {
    

  const [list, SetList] = useState();                                               // список со значениями

  const [showOptions, setShowOptions] = useState(false);                            // показывать или скрывать список

  const [selectedOption, setSelectedOption] = useState({label: "", value: 0});      // выбранное значение


  var list_values = [];     // список со строками


  // вызывается, когда изменяется список
  useEffect(() => {       
    FillList(props.options); 
    },[props.options]);

  // вызывается, когда меняется значение элемента
  useEffect(() => {      
    if(props.value !== undefined) setSelectedOption(props.value);           
    else setSelectedOption(selectedOption);  
  }, [props.value]); 
 /*
  useEffect(() => {
    if(selectedOption.value > 0) setSelectedOption(selectedOption); 
    else if(selectedOption.value == 0) if(list_values.length > 0) props.onChange(list_values.shift());     
  }, [props.defaultValue]); 
*/
  return (
    <div className={ getSelectClassName() }  onClick={() => setShowOptions(!showOptions)} >
      <input type="text" className={ getInputClassName() }
          value={ selectedOption.label }          
          onChange={ (e)=> ChangeText(e.target.value) }
          onKeyUp={ (e) => ChangeFilter(e.target.value)}
          onBlur={handleBlur}
      />
      
      <div className={`MySelectList ${showOptions ? 'show' : ''}`} >      
        <ul className="MySelectUL">
          { list }
        </ul>
      </div>
        
      
    </div> 
  );


  function ChangeText(e)
  {    
    props.onChange({label: e, value: -1});
  }

  function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms)); 
  }
  async function handleBlur()
  {    
    await wait(200);
    setShowOptions(false);
  }

  function handleSelect (value, label)
  {  
    setShowOptions(false);
    props.onChange({value: value, label: label});
  }


  function ChangeFilter(val)
  {
    FillList(filter_list(val));
  }
  function filter_list(value)
  {    
      let val = value.toLowerCase();
      let res_arr = [];
      for(var i in props.options)
      {
          if(props.options[i].label.toLowerCase().includes(val)) res_arr[i] = props.options[i];

      }
      return res_arr;
  }
     

  function getInputClassName()
  {
    let cn = "MySelectInput " + props.cnInput;
    if(selectedOption.value < 0) cn += " new_value";
    return cn;
  }
  function getSelectClassName()
  {
    return "MySelect " + props.cnSelect;
  }

  function FillList(m_list)
  {
    let res = [];
    list_values = [];
    for(var i in m_list)
    {
        list_values.push(m_list[i]);  // добавим в строку
        let s = m_list[i];
        let key = crypto.randomUUID();
        res.push(
          <li key={key} value={s.value} onClick={() => handleSelect(s.value, s.label)}>{s.label}</li>
        );
    }
    SetList(res);    
  }

}

export default MySelect;
