/*

    Форма авторизации
*/

import React , { useState, useEffect } from 'react';
import Select from 'react-select'

import './UserRegistration.css';
import axios_sender from '../modules/axios_sender';
import fill_list_array from '../modules/load_list.js';
import log_array from '../modules/log_array.js';


var address_array_current = [];   // массив адресов

function UserRegistration() {


  const [user_login, setUserLogin] = useState('');     // логин
  const [user_password, setUserPassword] = useState('');     // логин
  const [user_name, setUserName] = useState('');     // логин
  const [id_reduct_user, setId_reductUser] = useState(0);

  // чекбоксы
  const [right_blank, setRightBlank] = useState(true);
  const [right_list, setRightList] = useState(true);
  const [right_drug, setRightDrug] = useState(true);
  const [right_user, setRightUser] = useState(false);

  var m_user_array = [];  // массив пользователей
  const [user_array, setUserArray] = useState([]);

  // список адресов и выбранный адрес
  const [address_array, setAddressArray] = useState([]);        // для обновления выпадающего списка
  const [selected_address, setSelectedAddress] = useState([]);  // выбранный адрес
  

  
  useEffect(() => {   
      LoadUsers();    // загрузим пользователей
      LoadAddress();   // загрузим адреса
    }, []);


//#region   
  return (
    <div className="container justify-content-center border border-1 rounded">
      { FormReductUser() }
      { FormUsers() }
    </div>
//#endregion
  );

  //#region   Форма с пользователями
  function FormUsers()
  {
    return (
      <div className="listUser border border-1 rounded">
        {user_array}
      </div>
    );
  }
  //#endregion


  //#region   Форма ввода/редактирования пользвателей
  function FormReductUser()
  {
    return (

      <div className="reductUser border border-1 rounded">
          { FillReductUser() }
        <div className="row mb-2">
          <div className="col-1">Логин</div>
          <div className="col-2"><input className="form-control" value={user_login} onChange={(e) => setUserLogin(e.target.value)} /></div>
          <div className="col-1">Пароль</div>
          <div className="col-2"><input className="form-control" value={user_password} onChange={(e) => setUserPassword(e.target.value)} /></div>
          <div className="col-1">Имя</div>
          <div className="col-4"><input className="form-control" value={user_name} onChange={(e) => setUserName(e.target.value)} /></div>
          <button type="button" className="btn btn-sm btn-info col-1" onClick={ClickButtonSave}>Сохр.</button>
        </div>

        <div className="row mb-2">
          <div className="col-1">Адрес</div>
          <div className="col-6"><Select options={address_array} value={selected_address} onChange={(choice) => { setSelectedAddress(choice); }} ></Select></div>          
          <div className="col-5"></div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <input type="checkbox" checked={right_blank} onChange={(e) => setRightBlank(e.target.checked)}/> <span>Рецепты</span>
            <input type="checkbox" checked={right_list} onChange={(e) => setRightList(e.target.checked)} /> <span>Списки</span>
            <input type="checkbox" checked={right_drug} onChange={(e) => setRightDrug(e.target.checked)} /> <span>Препараты</span>
            <input type="checkbox" checked={right_user} onChange={(e) => setRightUser(e.target.checked)} /> <span>Пользователи</span>

          </div>
      </div>
    </div>
    );
  }
  //#endregion


  // пользователь сохраняет пользователя
  function ClickButtonSave()
  {
    let login = user_login;
    let password = user_password;
    let name = user_name;
    let rights = [];
    if(right_blank) rights.push("recipe");
    if(right_list) rights.push("list_reduct");
    if(right_drug) rights.push("drug_reduct");
    if(right_user) rights.push("user_reduct");

    let right = "";
    for(var i in rights)
    {
      if(right != '') right += ",";
      right += rights[i];
    }

    let prm = {login: login, password: password, name: name, rights: right, id_address: selected_address.value};
    // либо редактируем, либо сохраняем
    if(id_reduct_user > 0) axios_sender({form: 'reduct_user', id_user: id_reduct_user, params: prm }, ClickButtonSaveSuccess);
    else axios_sender({form: 'add_user', params: prm}, ClickButtonSaveSuccess);

  }

  function ClickButtonSaveSuccess(data)
  {
    LoadUsers();
    CancelReductUser();
  }


  function FillReductUser()
  {
    if(id_reduct_user > 0)
      return (<div className="SelectUser"><span>ID: {id_reduct_user}</span>  РЕДАКТИРОВАНИЕ ПОЛЬЗОВАТЕЛЯ <button className="btn btn-sm btn-warning" type="button" onClick={CancelReductUser}>Отменить</button></div>);
    else return (<div className="NewUser">Новый пользователь</div>);
  }
  

  // Пользователь отказывается редактировать пользователя
  function CancelReductUser()
  {
    setId_reductUser(0);
    setUserLogin("");
    setUserPassword("");
    setUserName("");
    setRightUser(false);
  }

  //#region   Загрузка адресов
  function LoadAddress() { fill_list_array('address', LoadAddressSuccess); }
  function LoadAddressSuccess(arr) { address_array_current = arr; setAddressArray(address_array_current); }
  //#endregion

  //#region   Загрузка пользователей
  function LoadUsers()
  {
    axios_sender({form: 'get_users'}, LoadUsersSuccess);
  }
  function LoadUsersSuccess(data)
  {    
    m_user_array = [];
    for(let i in data)
    {
      let s = data[i];
      let one = {
        id: s.ID,
        login: s.login,
        name: s.name,
        rights: s.rights,
        id_address: s.id_address
      };
      m_user_array.push(one);
    }

    RefreshList();
  }
  function RefreshList()
  {
    let res = [];
    for(var i in m_user_array)
    {
      let s = m_user_array[i];
      let key = crypto.randomUUID();
      res.push(
        <div className="OneUser" onClick={()=>SelectedUser(s.id)} key={key}>
          <div className="row">
            <div className="col-1"><span>id: </span>  <span>{s.id}</span></div>
            <div className="col-2"><span>login: </span>  <span>{s.login}</span></div>
            <div className="col-4"><span>name: </span>  <span>{s.name}</span></div>            
          </div>
          <div className="rights">{s.rights}</div>
        </div>
      );      
    }
    setUserArray(res);
  }
  //#endregion

  // пользователь кликнул в пользователя для его редактирования
  function SelectedUser(id_user)
  {  
    axios_sender({form: 'get_users_as_id', id_user: id_user}, SelectedUserSuccess);    
  }
  function SelectedUserSuccess(data)
  {  
    // заполним адрес
    let sa = [];
    for(let i in address_array_current)
    {      
      if(address_array_current[i].value == data.id_address)
      {
        sa = address_array_current[i];
        break;
      } 
    }
    setSelectedAddress(sa);

    

    setId_reductUser(data.id);
    setUserLogin(data.login);
    setUserPassword(data.password);
    setUserName(data.name);

    setRightBlank(false);
    setRightDrug(false);
    setRightList(false);
    setRightUser(false);

    let rights = data.rights.split(',');
    for(let i in rights)
    {
      switch(rights[i].trim())
      {
        case 'recipe': setRightBlank(true); break;
        case 'drug_reduct': setRightDrug(true); break;
        case 'list_reduct': setRightList(true); break;
        case 'user_reduct': setRightUser(true); break;
      }
    }

  }


}



export default UserRegistration;

