/*
  Просмотр / редактирование Бланка рецепта
*/

import React, { createRef, useEffect, useState } from 'react';
import { useParams } from "react-router";
import axios_sender from '../modules/axios_sender';
import log_array from '../modules/log_array.js'

import './LookRecipeBlank.css';
import ClientInfo from './ClientInfo/ClientInfo.js';
import MP from './MP/MP.js';
import getHTMLRecipe from './RecipePrint/getHTMLRecipe.js'


function LookRecipeBlank(props) {
    
    const params = useParams();     // входные параметры
    const refCI = createRef();      // ссылка на данные клиента/животного
    const refMP = createRef();      // ссылка на данные препаратов в рецепте


    const [recipe_date, setRecipeDate] = useState();


    // первоначальная загрузка
    useEffect(() => {
        getRecipeDate(params.id_recipe);      // Заполним дату рецепта

    }, []);  
  
    // запросим дату рецепта
    function getRecipeDate(id_recipe) { axios_sender({form: 'get_recipe_date', id_recipe: id_recipe}, getRecipeDateSuccess); }
    function getRecipeDateSuccess(data) { setRecipeDate(data); }


    // восстановить рецепт по умолчанию (загрузить из БД)
    function SetDefaultData(id_recipe)
    {
        refCI.current.LoadRecipeInfo(id_recipe);
        refMP.current.LoadRecipeDrugs(id_recipe);
    }

    
  return (
    
    <div className="container-fluid">
        <div className="container-fluid row mb-2">
            <div className="col-8">
                Редактирование рецепта ID: <span>{params.id_recipe}</span> от <span>{recipe_date}</span>
            </div>
            <div className="col-2"><button type="button" className="btn btn-info" onClick={ PrintRecipe }>Печатать рецепт</button></div>
        </div>

        <div className="container-fluid row">
            <div className="col-4">
                <ClientInfo ref={refCI} reductRecipe={params.id_recipe}/>

                <div className="reductRecipe row">
                    <div className="col-5">
                        <button type="button" className="btn btn-outline-danger" onClick={ () => SetDefaultData(params.id_recipe) }>Вернуть как было</button>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-5">
                        <button type="button" className="btn btn-warning" onClick={ () => ReSaveRecipe(params.id_recipe) }>Пересохранить</button>
                    </div>                            
                </div>

            </div>

            <div className="col-8"><MP ref={refMP} reductRecipe={params.id_recipe}/></div>
        </div>
    </div> 
  );

  //#region   Функция печати рецепта
  function PrintRecipe()
  {
      // получим html-рецепт(ы)
      let ci = refCI.current.GetRecipeData();
      let mp = refMP.current.GetRecipeData();
      var html = getHTMLRecipe(ci, mp);        

      const mywindow = window.open('', 'PRINT');
		if (!mywindow) return;

		mywindow.document.write(html);

		mywindow.document.close(); 
		mywindow.focus(); 

		mywindow.print();
		mywindow.close();
    }
  //#endregion
  
  //#region функция сохранения рецепта
    function ReSaveRecipe(id_recipe)
    {
      let ci = refCI.current.GetRecipeData();
      let mp_src = refMP.current.GetRecipeData();
      let mp = [];
      for(var i in mp_src) mp.push(mp_src[i].data);

      console.log(mp);


      let cl_subject = "";
      if(ci.client_default_address) cl_subject = "Челябинская область, г.Снежинск";

      let clientInfo = {
        client_name_1: ci.client_name_1,
        client_name_2: ci.client_name_2,
        client_name_3: ci.client_name_3,
        client_address: ci.client_address,
        client_subject: cl_subject,
        client_town: ci.client_town,
        client_street: ci.client_street,        
        client_house: ci.client_house,
        client_flat: ci.client_flat,
        animal_type: ci.animal_type,
        animal_sex: ci.animal_sex,
        animal_age: ci.animal_age,
        animal_weight: ci.animal_weight,
        animal_name: ci.animal_name,
        animal_qty: ci.animal_qty,
        recipe_date: ci.recipe_date,
      }
      
      // отправим данные для сохранения рецепта
      axios_sender({form: 'resave_recipe', ci:clientInfo, mp: mp, id: id_recipe}, SaveRecipeSuccess);
    }
    function SaveRecipeSuccess(data) {  }
    

  //#region 

}

export default LookRecipeBlank;


