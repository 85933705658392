/*

    Форма авторизации
*/

import React , { useState } from 'react';

import './Authorize.css';
import axios_sender from '../modules/axios_sender';



function Authorize({onAuthorize}) {

  const [user_login, setUserLogin] = useState('');     // логин
  const [user_password, setUserPassword] = useState('');     // логин

//#region   
  return (
    <div className="d-flex justify-content-center border border-1 rounded">
      <div className="Authorize">
          
        <label className="form-label mb-1">Авторизация</label>

        <div className="input-group mb-1">
          <span className="input-group-text col-3">Логин</span>
          <input type="text" className="form-control" value={ user_login } onChange={(event) => setUserLogin(event.target.value)} />
        </div>
        <div className="input-group mb-1">
          <span className="input-group-text col-3">Пароль</span>
          <input type="password" className="form-control" value={ user_password } onChange={(event) => setUserPassword(event.target.value)} />
        </div>

        <div className="input-group mb-1">
          <button type="button" className="btn btn-secondary col-5" >Отмена</button>
          <div className="col-2"></div>
          <button type="button" className="btn btn-secondary col-5" onClick={ AuthorizeSender }>ОК</button>
        </div>


      </div>
 
    </div>
//#endregion
  );


  function AuthorizeSender()
  {
    axios_sender({form: 'authorization', login: user_login, password: user_password}, AuthorizeSenderSuccess);
  }
  function AuthorizeSenderSuccess(data)
  {
      if(data.id > 0)
      {            
        onAuthorize(true);
        localStorage.setItem('id_user', data.id);
        localStorage.setItem('user_name', data.name);
        localStorage.setItem('token', data.token);
        localStorage.setItem('rights', data.rights);
        console.log("Пользователь авторизировался");
      }
      else
      {
        localStorage.clear();
      }
  }
  
}



export default Authorize;
