/*
  Списки
*/

import React , { useState }from 'react';
import { ajaxPath } from '../global_var.js'
import axios_sender from '../modules/axios_sender.js'
import './Lists.css';

// имя выбранной для редактирования таблицы
var selected_table_name = "";

// список значений
let ValuesArray = [];

function Lists() {

  // список редактируемых таблиц
  const tables = [
    {name: "animal_type", capt: "Тип животного"},
    {name: "method_application", capt: "Способ применения"},
    {name: "dosage", capt: "Дозировка", dop: "factory"},
    {name: "dose_effect", capt: "Разовая доза"},
    {name: "unit_meas", capt: "Единица измерения"},
    {name: "often_use", capt: "Частота применения"},
    {name: "time_app", capt: "Время применения"},
    {name: "duration_use", capt: "Длительность применения"},
    {name: "release_form", capt: "Форма выпуска"},
    {name: "substance", capt: "Действующее вещество"},
    {name: "address", capt: "Адрес"},
    {name: "town", capt: "Город"},

  ];
  
  // Список таблиц
  const [tableNames, setTableNames] = React.useState(
    tables.map((element, index) => {
    return <li key={element.name} className="list-group-item" onClick={() => OnClickTable(element)}> {element.capt}</li>;
  }));
  
  const [selectedTable, setSelectedTable] = useState({name: "", capt:""}); // название таблицы
  const [valueTable, setValuesTable] = useState(); // значения в списке

  const [newValue, setNewValue] = useState(''); // значения в списке



  return (
    <div className="container row">
      
      <div className="col-4 Lists border border-1 rounded m-2">
        <label className="capt">Списки</label>
        <ul className="list-group list"> { tableNames } </ul>
      </div>

      <div className="col-4 Lists border border-1 rounded m-2">
        
        <label className="capt1">Название списка: <strong>{selectedTable.capt}</strong></label>
        <div className="input-group mb-3">          
          <input type="text" className="form-control" placeholder="Новое значение" value={ newValue } onChange={(event) => setNewValue(event.target.value)}/>
          <button className="btn btn-sm btn-outline-secondary" type="button" onClick={ AddNewValue }>+</button>
        </div>
        <div className="tableValues">
            <ul className="list-group listTable">
            { valueTable }
            </ul>
        </div>        
      </div>


    </div> 
  );

  // Пользователь кликнул в название таблицы. Желает получить список значений
  function OnClickTable(elem)
  {

    selected_table_name = elem.name;   // текущая таблица для редактирования

    // перересуем список таблиц, чтобы отметить выбранную таблицу
    let ul = 
    tables.map((element, index) => {
      let className = "list-group-item";
      if(element.name == elem.name) className += " selected";
      return <li key={element.name} className={className} onClick={() => OnClickTable(element)}> {element.capt}</li>;
    });
    setTableNames(ul);    

    // отметим название списка
    setSelectedTable({name: elem.name, capt:elem.capt});
    // заполним список значениями
    FillTableValues(elem.name);
  }
//#region Запросить список значений из БД
  // заполнить список значениями
  async function FillTableValues(table_name)
  {
    axios_sender({form: 'get_list', table_name: table_name,}, FillTableValuesSuccess);
  }

  function FillTableValuesSuccess(data)
  {
    // заполним список полученный от БД
    ValuesArray = [];
    for(var i in data)
    {
      let s = data[i];
      ValuesArray.push({id: s.ID, name: s.name});
    }            

    // отправим этот список сформировать
    ReplaceListValue();       
  }
//#endregion

//#region перестроить список значений
  // перезаполнить список значений в зависимости от изменений. С БД не связываемся
  function ReplaceListValue()
  {
    let li = [];
    for(var i in ValuesArray)
    {      
      let s = ValuesArray[i];
      let key = selected_table_name + ValuesArray[i].id;
      let rowid = "rowid" + s.id;
      li.push(<li key={key} className="list-group-item tableValue">
        <div className="input-group tableValue">
        <input type="text" className="form-control" defaultValue={s.name} id={rowid} onKeyUp={e => e.key === 'Enter' && RedactValue(s.id, rowid)} />
        <button className="btn btn-outline-secondary" type="button" onClick={ (event) => DeleteRow(s.id, event) }>X</button>
        </div>                
      </li>);
    }

    setValuesTable(li);      
  }
//#endregion  

//#region  Функция удаления строки  
  // пользователь удаляет элемент из списка
  async function DeleteRow(id)
  {
    axios_sender({form: 'delete_list', table_name: selected_table_name, id: id, }, DeleteRowSuccess, id); 
  }
  function DeleteRowSuccess(data, id)
  {
    // если возвращается пустая строка, то строка удалена
    if(data == null) 
      {                           
        let index = -1;
        for(let i = 0; i < ValuesArray.length; i++)
          if(ValuesArray[i].id == id)
          {
            index = i;                            
            break;
          }

        if(index >= 0) ValuesArray.splice(index, 1);

        // отправим этот список сформировать
        ReplaceListValue();  
      }
  }
//#endregion  

//#region  Функция добавления строки
  // пользователь добавляет строку в список
  async function AddNewValue()
  {    
    axios_sender({form: 'add_list', table_name: selected_table_name, val: newValue, }, AddNewValueSuccess);
  }
  function AddNewValueSuccess(data)
  {
    // заполним список полученный от БД
    ValuesArray.push({id:data.ID, name: data.name});
    // отправим этот список сформировать
    ReplaceListValue();  

    // очистим поле ввода
    setNewValue('');
  }
//#endregion

//#region Пользователь нажимает кнопку на строке из списка (редактирует значение)
  async function RedactValue(id, rowid)
  {
    var val = document.getElementById(rowid).value;
    axios_sender({form: 'redact_list', table_name: selected_table_name, id: id, val: val, }, RedactValueSuccess);     
  }
  function RedactValueSuccess(data)
  {
    // подправим список, полученный от БД
    for(var i in ValuesArray)
      if(ValuesArray[i].id == data.id)
      {
        ValuesArray[i].name = data.name;
        break;
      }
    // отправим этот список сформировать
    ReplaceListValue();
  }
//#endregion
}

export default Lists;
