/*
  Список рецептов
*/

import React, {useState, useEffect} from 'react';
import { useParams } from "react-router";
import axios_sender from '../modules/axios_sender';
import log_array from '../modules/log_array.js'
import getRecipeNum from '../modules/recipe_get_num.js'
import str_to_num from '../modules/string.js'


import './RecipeRepay.css';


var DrugsArray = [];      // массив лекарств в рецепте

function RecipeRepay() {

  const params = useParams();     // входные параметры

  const [recipe_date, setRecipeDate] = useState();
  const [recipe_num, setRecipeNum] = useState();

  const [client_name, setClientName] = useState();
  const [client_address, setClientAddress] = useState();

  const [animal_type, setAnimalType] = useState();
  const [animal_name, setAnimalName] = useState();
  const [animal_sex, setAnimalSex] = useState();
  const [animal_weight, setAnimalWeight] = useState();
  const [animal_age, setAnimalAge] = useState();
  const [animal_qty, setAnimalQty] = useState();

  const [drug_list, setDrugList] = useState(<strong>Список препаратов пуст...</strong>);  

  const [button_add_repay, SetButtonAddRepay] = useState(true);


  useEffect(() => {
          GetRecipeInfo(params.id_recipe); 

      }, []);  
  
  return (
    <div className="container row ReceipeRepay">
      <div className="col-12">
        <label className="border border-1 rounded">Гашение препаратов в рецепте от { recipe_date }</label>

        { !button_add_repay && <div className="FullRepay"><h1>РЕЦЕПТ ПОЛНОСТЬЮ ПОГАШЕН</h1></div> }

        <div className="ClientInfoRepay">
          <label className="">Данные о владельце</label>
          <div className="row">
            <div className="col-5">ФИО: &nbsp;<span>{ client_name }</span></div>
            <div className="col-7">адрес: &nbsp;<span>{ client_address }</span></div>
          </div>
        </div>

        <div className="AnimalInfoRepay">
          <label className="">Данные о животном</label>
          <div>
            Тип:&nbsp;<span>{ animal_type }</span>;
            Кличка:&nbsp;<span>{ animal_name }</span>;
            Пол:&nbsp;<span>{ animal_sex }</span>;
            Возвраст:&nbsp;<span>{ animal_age }</span>;
            Вес:&nbsp;<span>{ animal_weight }</span>;
            Кол-во:&nbsp;<span>{ animal_qty }</span>;
            
          </div>
        </div>
      

        <label className="border border-1 rounded">Препараты в рецепте</label>
        { drug_list }

        { button_add_repay && 
        <div><button type="button" className="btn btn-primary" onClick={ SaveIssue }>Сохранить выдачу препаратов</button></div>
        }


      </div>
    </div> 
  );

  //#region   Получить данные о рецепте
    function GetRecipeInfo(id_recipe)
    {
      GetRecipeCIInfo(id_recipe);
      GetRecipeMPInfo(id_recipe);      
    }
    function GetRecipeCIInfo(id_recipe) { axios_sender({form: 'get_recipe', id: id_recipe}, GetRecipeCIInfoSuccess); }
    function GetRecipeCIInfoSuccess(data)
    {

        // спрячем кнопку добавления выдачи лекарств, если рецепт погашен
        if(data.state > 0) SetButtonAddRepay(false);

        setRecipeDate(data.datetime);        
        setClientName(data.client_name1 + " " + data.client_name2 + " " + data.client_name3 );
        setClientAddress(data.client_subject + " " + data.client_address);

        setAnimalType(data.m_animal_type);
        setAnimalName(data.animal_name);

        if(data.animal_sex == 1) setAnimalSex("М");
        else setAnimalSex("Ж");

        setAnimalWeight(data.animal_weight);
        setAnimalAge(data.animal_age);
        setAnimalQty(data.animal_qty);

        setClientAddress(data.client_town + ", " + data.client_street + ", " + data.client_house + ", " + data.client_flat);
    }

    function GetRecipeMPInfo(id_recipe) { axios_sender({form: 'recipe_get_drugs', id: id_recipe}, GetRecipeMPInfoSuccess); }
    function GetRecipeMPInfoSuccess(data)
    { 
      DrugsArray = [];
      for(var i in data)
      {
          data[i].qty_repay = 0;
          data[i].qty_shell_issue = 0;
          DrugsArray.push(data[i]);
      }
        
      // получить ранее выданные по рецепту лекарства
      GetRecipeRepayInfo(params.id_recipe);                  
    }

    function DrugsArrayRefresh()
    {
      let list = [];
      for(let i in DrugsArray)
      {
        let s = DrugsArray[i];
  
        // qty_issue - сколько надо выдать, qty_repay - сколько уже выдали, qty_shell_issue - сколько выдаём
        if(parseFloat(s.qty_issue) > parseFloat(s.qty_repay)) list.push(getNoRepayDrug(s, i));
        else list.push(getRepayDrug(s, i));        
      }      
      setDrugList(list);
    }



    // возвращает не погашенное лекарство
    function getNoRepayDrug(s, i)
    {
        // определим класс для ранее выданных препаратов
        let cn = "col-9 QtyRepay";
        if(s.qty_repay <= 0) cn += " QtyRepayHide";

        let key = "mp_" + i;

        return (
            <div className="container-fluid Drug" key={ key }>

              <div className="row">
                <div className="col-1">
                  <div>№</div>
                  <div>{ getRecipeNum(s.num) }</div>
                </div>

                <div className="col-9">
                  <div><span>{ s.drug }</span></div>
                  <div className="row">
                    <div className="col drugInfo">дозировка: <span>{ s.dosage } { s.unit_meas }</span></div>
                    <div className="col drugInfo">форм. вып.: <span>{ s.release_form }</span></div>
                    <div className="col drugInfo">выдать всего: <span>{ s.qty_issue }</span></div>

                  </div>
                </div>

                <div className="col-2">
                  <div>Выдаём</div>
                  <div className="row">
                    <div className="col-7">
                        <input type="text" className="form-control form-control-sm" 
                          value = { s.qty_shell_issue }
                          onChange={ (e) => {
                                      s.qty_shell_issue = str_to_num(e.target.value);
                                      DrugsArrayRefresh();
                                  } }
                        />
                    </div>
                    <div className="col-5"><button type="button" className="DrugRepay" title="Посчитать, сколько выдать" onClick={ () => CalcQty(i) }></button></div>
                  </div>
                </div>

                <div className={cn}>
                  <span>Ранее выдано: { s.qty_repay }</span>
                </div>

              </div>              
            </div>
        );       
    }

    // возвращает погашенное лекарство
    function getRepayDrug(s, i)
    {
        return (
          <div className="container-fluid DrugRepay row" key={crypto.randomUUID()}>
            <div className="col-1 num">
              <div>№</div>
              <div>{ getRecipeNum(s.num) }</div>
            </div>
            <div className="col-11">
              <span className="rd_v">{ s.drug }</span> <span className="rd_c">ВАДАН ПОЛНОСТЬЮ.</span>
            </div>
          </div>
        );       
    }


    // посчитать, сколько препарата надо выдать
    function CalcQty(i)
    {
      DrugsArray[i].qty_shell_issue = DrugsArray[i].qty_issue - DrugsArray[i].qty_repay;
      DrugsArrayRefresh();
    }


    // получить для рецепта выданные лекарства
    function GetRecipeRepayInfo(id_recipe) { axios_sender({form: 'recipe_get_repay', id_recipe: id_recipe}, GetRecipeRepayInfoSuccess); }
    function GetRecipeRepayInfoSuccess(data)
    {
      for(var i in data)
      {
        for(var j in DrugsArray)      
          if(DrugsArray[j].id_drug == data[i].id_drug)
            {
              DrugsArray[j].qty_repay = data[i].qty;
              break;
            }
      }
      DrugsArrayRefresh();
    }

  //#endregion


  // добавить выдачу препаратов
  function SaveIssue()
  {
    // проверим, может ничего не выдаём?
    let is_null = true;
    for(var i in DrugsArray) if(DrugsArray[i].qty_shell_issue > 0) { is_null = false; break; }
    if(is_null)
    {
      alert("Ничего не выдаёся?");
      return;
    }
    axios_sender({form: 'recipe_add_repay', id_recipe: params.id_recipe, drugs: DrugsArray}, SaveIssueSuccess);
  }
  function SaveIssueSuccess()
  {
    ClearShellIssue();    

    // проверим, может рецепт полностью погашен
    axios_sender({form: 'recipe_is_repay', id_recipe: params.id_recipe}, () => GetRecipeInfo(params.id_recipe) );
  }
  
  // очистить строки с вычитами
  function ClearShellIssue()
  {
    for(var i in DrugsArray) DrugsArray[i].qty_shell_issue = 0;
  }
}

export default RecipeRepay;


