import './App.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import axios_sender from './modules/axios_sender';

import Authorize from './Authorize/Authorize.js';
import RecipeBlank from './RecipeBlank/RecipeBlank.js';
import RecipeList from './RecipeList/RecipeList.js';
import TopMenu from './TopMenu/TopMenu.js';
import Lists from './Lists/Lists.js';
import DrugList from './DrugList/DrugList.js';
import LookRecipeBlank from './RecipeBlank/LookRecipeBlank.js';
import RecipeRepay from './RecipeRepay/RecipeRepay.js';
import UserRegistration from './UserRegistration/UserRegistration.js'



import Header from './Header/Header.js';


function App()
{

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  useEffect(() => { 
/*    
        const id_user = localStorage.getItem('id_user');
    if (id_user > 0) setIsAuthenticated(true);
    else setIsAuthenticated(false); 
*/
    GetAuthentification()
    }, []);

  function GetAuthentification()
  {
    axios_sender({form: 'is_user'}, GetAuthentificationSuccess);
  }
  function GetAuthentificationSuccess(data)
  {
    setIsAuthenticated(data);
  }

    return (    
    
      isAuthenticated ? (
        <div className="App">
      <BrowserRouter> 
        <Header />
        <TopMenu onOutAuthorize={setIsAuthenticated}/>        
        <Routes>                
          <Route path="/entry" element={<Authorize />} />        
          <Route path="/blank" element={<RecipeBlank />} />
          <Route path="/recipes" element={<RecipeList />} />
          <Route path="lists" element={<Lists />} />
          <Route path="/drug_list" element={<DrugList />} />          
          <Route path="/user_reduct" element={<UserRegistration />} />

          <Route path="/look" element={<LookRecipeBlank />}>
                <Route path=":id_recipe" element={<LookRecipeBlank />} />
          </Route> 

          <Route path="/repay" element={<RecipeRepay />}>
                <Route path=":id_recipe" element={<RecipeRepay />} />
          </Route> 
  
          <Route path="" element={<RecipeBlank />} />
 
        </Routes>
      </BrowserRouter>
      </div>  
        
        ) : (
          <div className="App">
            <BrowserRouter> 
            <Header />
            <Routes>
              <Route path="*" element={<Authorize onAuthorize={setIsAuthenticated} />} />
            </Routes>
            </BrowserRouter>
          
          </div>
        )        
      );  

}




export default App;
