/*
  Инфоромация о клиенте    
*/

import React, { useEffect, useState, useImperativeHandle } from 'react';
import Select from 'react-select'
import MySelect from '../../elements/MySelect.js'

import { AddressSuggestions } from 'react-dadata';

import 'react-dadata/dist/react-dadata.css';

import axios_sender from '../../modules/axios_sender';
import str_to_num from '../../modules/string.js'

import './ClientInfo.css';
import log_array from '../../modules/log_array.js';

class ClientInfo extends React.Component {
  constructor(props, ref) {
    super(props, ref); 
    
    this.flag_recipe_loaded = { loaded: false, execute: false };      // true, когда рецепт будет загружен
    this.flag_town_selected = { loaded: false, execute: false };      // true, когда будет выбран город и загружены улицы
    this.flag_street_selected = { loaded: false, execute: false };    // true, когда улица выбрана
    this.flag_town_loaded = { loaded: false, execute: false };        // когда загружены города

    //this.selected_town = [];            // выбранный город
    this.selected_street = [];          // выбранная улица
  }

  state = {
    optionsAnimal: 0,
    optionsTown: [],
    optionsStreet: [],
    client_name_1: '',
    client_name_2: '',
    client_name_3: '',
    client_address: '',
    client_town: {value: "", label: ""},
    client_street: {value: "", label: ""},
    client_house: '',
    client_flat: [],
    //client_default_address: true,
    animal_type: 1,
    animal_sex: 1,
    animal_age: '',
    animal_weight: '',
    animal_name: '',
    animal_qty: 1, 
    recipe_date: this.getToday(),
    user_address: '',    

  }

  componentDidMount() { this.loadedObject(); }
  componentDidUpdate()
  {  

    // когда загрузился рецепт, значит поставлен город, загрузим улицы для города
    if(this.flag_recipe_loaded.loaded && !this.flag_recipe_loaded.execute)
    {
        this.flag_recipe_loaded.execute = true;
        this.fillStreets(this.state.client_town.value);        
    }
    // если загружены улицы, но не выбраны, то выберем
    if(this.flag_town_selected.loaded && !this.flag_town_selected.execute)
    {      
        this.flag_town_selected.execute = true;
        if(this.selected_street !== undefined && this.selected_street != '')
        {
          this.setState({client_street: this.selected_street});
        }
    }
    
   
    // если города только загрузились, то сделаем первый по умолчанию
    if(this.props.reductRecipe === undefined && this.flag_town_loaded.loaded && !this.flag_town_loaded.execute)
    {
      this.setState({client_town: this.optionsTown[0]});
      this.flag_town_loaded.execute = true;
      this.fillStreets(this.optionsTown[0].value);
    }
      
  }

  // при загрузке объекта
  loadedObject() {
    this.fillAnimalTypes();         // список с животными
    this.fillTowns();               // список с городами
        
    if(this.props.reductRecipe > 0) this.LoadRecipeInfo(this.props.reductRecipe);    
    this.getUserAddress();          // запросим адрес клиники
  }

  // возвращает сегодняшнюю дату
  getToday()
  {
    const today = new Date();
    return today.toISOString().substr(0, 10);
  }

  // запросим адрес клиники по пользователю
  getUserAddress() { axios_sender({form: 'get_user_address'}, this.getUserAddressSuccess, this); }
  getUserAddressSuccess(data, t) { t.setState({user_address: data}); }

  // запросить и заполнить список с типами животных
  fillAnimalTypes()
  {    
    axios_sender({form: 'get_animal_types'}, this.fillAnimalTypesSuccess, this);
  }
  fillAnimalTypesSuccess(data, obj)
  {    
    let opts = [];
    for(var i in data)
    {
      opts.push(<option key={data[i].ID} value={data[i].ID}> { data[i].name }</option>);
    }              
    obj.setState( obj.optionsAnimal = opts );
  }

  // загрузим города
  fillTowns(){ axios_sender({form: 'get_list', table_name: 'town'}, this.fillTownsSuccess, this); }
  fillTownsSuccess(data, obj)
  {   
    let arr = [];
    for(var i in data)
    {
      let s = data[i];
      arr.push({value: s.ID, label: s.name});
    }
    obj.setState(obj.optionsTown = arr);     
    obj.flag_town_loaded.loaded = true;    // выставим флаг загрузки городов
  }

    // загрузим улицы города
    fillStreets(id_town){ axios_sender({form: 'get_streets', id_town: id_town}, this.fillStreetsSuccess, {t:this}); }
    fillStreetsSuccess(data, obj)
    {   
      let arr = [];
      let t = obj.t;
      for(var i in data)
      {
        let s = data[i];
        arr.push({value: s.ID, label: s.name});
      }
      t.setState(t.optionsStreet = arr);
      t.flag_town_selected.loaded = true;      

      t.setState({client_street:{value: 0, label: ''}});
    }


  // #region    очистить форму
  ClearForm()
  {
    this.setState({
      client_name_1: '',
      client_name_2: '',
      client_name_3: '',
      client_address: '',
      client_house: '',
      client_flat: '',
      client_town: {value:0, label: ""},
      client_street: {value:0, label: ""},
      //client_default_address: true,
      animal_type: 1,
      animal_sex: 1,
      animal_age: '',
      animal_weight: '',
      animal_name: '',
      animal_qty: 1,
      recipe_date: this.getToday(),
    });  
  }
  //#endregion

  // заполнить форму данными
  FillForm(data)
  {   
    this.setState({      
      client_name_1: data.client_name1,
      client_name_2: data.client_name2,
      client_name_3: data.client_name3,
      client_address: data.client_address,
      client_town: { value: data.ID_town, label: data.client_town },
      client_street: { value: data.ID_street, label: data.client_street },
      client_house: data.client_house,
      client_flat: data.client_flat,
      animal_type: data.animal_type,
      m_animal_type: data.m_animal_type,
      animal_sex: data.animal_sex,  
      animal_age: data.animal_age,
      animal_weight: data.animal_weight,
      animal_name: data.animal_name,
      animal_qty: data.animal_qty,
      recipe_date: data.datetime,
      m_doctor: data.m_doctor,
    });

    this.flag_recipe_loaded.loaded = true;     //рецепт был загружен

    this.selected_street = { value: data.ID_street, label: data.client_street };
  }

  // подготовить и вернуть данные о рецепте
  GetRecipeData()
  {
    // можем отправить все данные, но там есть лишние, поэтому переподготовим их
    let res_data = {
    client_name_1: this.state.client_name_1,
    client_name_2: this.state.client_name_2,
    client_name_3: this.state.client_name_3,
    client_address: this.state.client_address,
    client_town: this.state.client_town,
    client_street: this.state.client_street,
    client_house: this.state.client_house,
    client_flat: this.state.client_flat,
    //client_default_address: true,
    animal_type: this.state.animal_type,
    animal_sex: this.state.animal_sex,
    animal_age: this.state.animal_age,
    animal_weight: this.state.animal_weight,
    animal_name: this.state.animal_name,
    animal_qty: this.state.animal_qty, 
    recipe_date: this.state.recipe_date,
    user_address: this.state.user_address,
    m_doctor: this.state.m_doctor,
    m_animal_type: this.state.m_animal_type,
    }

    return res_data;
  }


  // загрузить рецепт из БД
  LoadRecipeInfo(id_recipe) { axios_sender({form: 'get_recipe', id: id_recipe}, this.LoadRecipeInfoSuccess, this); }
  LoadRecipeInfoSuccess(data, t) { t.FillForm(data); }

  render() {       

    let animal_sex = ["","","","","" ];
    animal_sex[this.state.animal_sex] = true;       

    return (
      <div className="ClientInfo border border-1 rounded">
        <label className="form-label form-control mb-1 CI">Информация о владельце</label>
  
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Фамилия</span>
          <input type="text" className="form-control" id="client_name1" value={this.state.client_name_1} onChange={(e) => this.setState({client_name_1: e.target.value})} />
        </div>
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Имя</span>
          <input type="text" className="form-control" id="client_name2" value={this.state.client_name_2} onChange={(e) => this.setState({client_name_2: e.target.value})}/>
        </div>
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Отчество</span>
          <input type="text" className="form-control" id="client_name3" value={this.state.client_name_3} onChange={(e) => this.setState({client_name_3: e.target.value})}/>
        </div>
  
        <label className="form-label form-control mb-1 input-group-sm">Адрес пребывания</label>                

        <div className="input-group mb-1">
          <span className="input-group-text col-4">Город</span>
          <MySelect cnInput="form-control" cnSelect="col"
              value={this.state.client_town}
              options={ this.optionsTown }
              onChange={ (e) => { this.setState({client_town: e}); this.fillStreets(e.value); } }
              
          />
        </div>
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Улица</span>
          <MySelect cnInput="form-control" cnSelect="col"              
              options={this.optionsStreet}
              onChange={ (e) => this.setState({client_street: e}) }              
              value={this.state.client_street}
          />
        </div>
        <div className="input-group mb-1">
          <span className="input-group-text col-2">Дом</span>
          <input type="text" className="form-control" value={this.state.client_house} onChange={(e) => this.setState({client_house: e.target.value})} />
          <span className="input-group-text col-2">Кв.</span>
          <input type="text" className="form-control" value={this.state.client_flat} onChange={(e) => this.setState({client_flat: e.target.value})}/>
        </div>


  
        <label className="form-label form-control mb-1 CI">Информация о животном</label>
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Вид</span>
          <select className="form-control" id="animal_type" value={this.state.animal_type} onChange={(e) => this.setState({animal_type: e.target.value})}> { this.optionsAnimal } </select>
        </div>

        <div className="input-group mb-1">
          <strong>Пол:</strong>          
          <input className="form-check-input-sm" type="radio" name="selectSex"  checked={animal_sex[1]} onChange={(e) => this.setState({animal_sex: 1})} />
          <strong>М</strong>
          <input className="form-check-input-sm" type="radio" name="selectSex" checked={animal_sex[2]} onChange={(e) => this.setState({animal_sex: 2})} />
          <strong>Ж</strong>
          <input className="form-check-input-sm" type="radio" name="selectSex" checked={animal_sex[3]} onChange={(e) => this.setState({animal_sex: 3})} />
          <strong>Ж+М</strong>
        </div>
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Возраст</span>
          <input type="text" className="form-control" id="animal_old" value={this.state.animal_age} onChange={(e) => this.setState({animal_age: str_to_num(e.target.value)})} />
        </div>
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Вес</span>
          <input type="text" className="form-control" id="animal_weight" value={this.state.animal_weight} onChange={(e) => this.setState({animal_weight: str_to_num(e.target.value)})} />
        </div>
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Кличка/ID</span>
          <input type="text" className="form-control" id="animal_name" value={this.state.animal_name} onChange={(e) => this.setState({animal_name: e.target.value})} />
        </div>
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Количество</span>
          <input type="text" className="form-control" id="animal_qty" value={this.state.animal_qty} onChange={(e) => this.setState({animal_qty: e.target.value})} />
        </div>
  
        <label className="form-label form-control mb-1 CI">Дата выдачи</label>
        <div className="input-group mb-1">
          <span className="input-group-text col-4">Дата</span>
          <input type="date" className="form-control" id="recipe_date" value={this.state.recipe_date} onChange={(e) => this.setState({recipe_date: e.target.value})} />        
        </div>
        
      </div>    
    );
  }
}
  
export default ClientInfo;