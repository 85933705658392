
import log_array from '../../modules/log_array.js'


// создание html-рецепта
function getHTMLRecipe(ci, mp)
{        
    let html = '<!DOCTYPE html>';
    html += '<html>';
    html += '   <head>';
    html += '       <meta charset="utf-8" />';
    html += getStyles();
    html += '   </head>';
    html += '   <body>';

    let page_p = 1;
    for(var i in mp)
    {
        for(let j = 1; j<=3; j++)
        {
            let new_page = true;
            if(page_p % 2 > 0) new_page = false;
            html += getOneRecipe(ci, mp[i].data, j, new_page);
            page_p ++;
        }
    }
    

    html += '   </body>';
    html += '</html>';

    return html;
}

function getOneRecipe(ci, mp_one, ex, pp)
{    

    // РУ препарата
    let drug_reg = "";

    if(mp_one.drug_reg_name !== undefined && mp_one.drug_reg_name != null && mp_one.drug_reg_name != "")
        drug_reg = ', ' + mp_one.drug_reg_name;

    let html = '';
    html += '<div class="exemp">Экземпляр №&nbsp;' + ex + '</div>';
    html += '<div class="caption">РЕЦЕПТУРНЫЙ БЛАНК</div>';
    html += '<div class="text">на лекарственный препарат для ветеринарного применения</div>';

    html += '<div class="text"><div class="ogrn">ОГРН/ОГРНИП&nbsp;';
    html += '<span class="ogrn">3</span><span class="ogrn">1</span><span class="ogrn">3</span><span class="ogrn">7</span>';
    html += '<span class="ogrn">4</span><span class="ogrn">5</span><span class="ogrn">9</span><span class="ogrn">2</span>';
    html += '<span class="ogrn">0</span><span class="ogrn">3</span><span class="ogrn">0</span><span class="ogrn">0</span>';
    html += '<span class="ogrn">0</span><span class="ogrn">1</span><span class="ogrn">2</span>';
    html += '</div></div>';
    html += '<div class="text">' + ci.user_address + '</div>';

    html += '<div class="flex"><div class="f1">&nbsp;</div><div class="f1 rb">место для отметки о срочном или немедленном изготовлении и отпуске</div></div>';


    html += '<div class="textB">РЕЦЕПТ №&nbsp;' + mp_one.num + '</div>';

    html += '<div class="flex"><div class="f1">&nbsp;</div><div class="date f1">' + convertDate(ci.recipe_date) + '</div><div class="f1">&nbsp;</div></div>';
    html += '<div class="flex"><div class="f1">&nbsp;</div><div class="date_text f1">(дата оформления рецепта)</div><div class="f1">&nbsp;</div></div>';

    
    html += '<div class="drug"><span class="li7">' + ci.client_name_1 + ' ' + ci.client_name_2 + ' ' + ci.client_name_3;
    html += ', ' + ci.client_town.label + ", " + ci.client_street.label + ", " + ci.client_house + ", " + ci.client_flat + '</span></div>';
    html += '<div class="drug_text">Владелец животного (животных), законный предстваитель владельца животного (животных): фамилия, имя, отчество (последнее при наличии) физического лица ';
    html += 'адрес регистрации по месту жительства (пребывания) физического лица</div>';



    html += '<div class="drug"><span class="l">тип животного:</span> ' + ci.m_animal_type + ', <span class="l">пол животного:</span> <span class="ogrn">' + getAnimalSex(ci.animal_sex) + '</span>';
    html += ', <span class="l">возраст, лет:</span> ' + ci.animal_age + ', <span class="l">количество:</span>' + ci.animal_qty + ', <span class="l">кличка:</span> ' + ci.animal_name + '</div>';
    html += '<div class="drug_text">Вид животного (животных), пол и возраст, количество голов, кличка или идентификационный номер животного (животных)</div>';
    

    html += '<div class="drug">' + mp_one.m_drug + '&nbsp;' + drug_reg + '</div>';
    html += '<div class="drug_text">Наименование лекарственного препарата, номер регистрационного удостоверения (при назначении зарегистрированных лекарственных препаратов)</div>';


    html += "<br>";
    html += '<div class="flex text left">';
    html += '<div class="f1 left">[ дозировка: <span class="b">' + mp_one.m_dosage + '</span>, ';
    html += 'доза: <span class="b">' + mp_one.dose + '</span>, частота: <span class="b">' + mp_one.m_often_use + '</span>, ';
    html += 'время применения: <span class="b">' + mp_one.m_time_app + '</span>, длительность применения: <span class="b">' + mp_one.m_duration_use + '</span>, ';
    html += 'способ применения: <span class="b">' + mp_one.m_method_application + '</span> ]</div>';
    html += '</div>';



    html += '<div class="text center i">';
    html += '<span class="b">' + mp_one.description + '</span>';
    html += '</div>';

    html += '<div class="drug i">' + ci.m_doctor + '</div>';
    html += '<div class="drug_text">Фамилия, имя, отчество (последнее - при наличии), подпись специалиста в области ветеринарии, оформившего рецепт</div>';    

    html += '<div class="flex text left">';
    html += '<div class="f1 left">Срок действия рецепта продлён до</div>';
    html += '<div class="f1 bb">&nbsp;</div>';
    html += '</div>';

    html += '<div class="drug i">&nbsp;</div>';
    html += '<div class="drug_text">Фамилия, имя, отчество (последнее - при наличии), подпись специалиста в области ветеринарии, продлившего рецепт</div>';


    html += '<div class="text i bb">Я, ' + ci.client_name_1 + ' ' + ci.client_name_2 + ' ' + ci.client_name_3 + ', даю согласие на обработку персональных данных</div>';
    html += '<div class="drug_text">Фамилия, имя, отчество (последнее при наличии) полностью</div>';

    html += '<div class="flex"><div class="f1">&nbsp;</div> <div class="f1 bb w30 exemp l">' + convertDate(ci.recipe_date) + '</div></div>';
    html += '<div class="flex"><div class="f1">&nbsp;</div> <div class="f1 w30 center"><span class="l">(подпись, дата)</span></div></div>';


    html += '<div class="creck';
    if(pp) html += ' next_page';
    html += '"></div>';

    return html;
}

function getStyles()
{
    let styles = '<style>';

    styles += '  div.exemp { text-align: right; }';
    styles += '  div.left { text-align: left; }';
    styles += '  div.center { text-align: center; }';
    styles += '  div.i { font-style: italic; }';
    styles += '  div.bb { border-bottom: 1px solid #444444; }';
    styles += '  div.caption { width: 100%; text-align: center; font-weight: bold; font-size: 13pt; }';
    styles += '  div.text { width: 100%; text-align: center; font-weight: normal; font-size: 10pt; }';
    styles += '  div.ogrn { width: 100%; text-align: center; font-weight: normal; font-size: 10pt; padding-top: 2pt; padding-bottom: 2pt;}';
    styles += '  span.ogrn { border: 1px solid black;  margin-left: 1px; margin-right: 1px; padding-left: 1px; padding-right: 1px;}';

        styles += '  span.li7{ font-size: 8pt; font-style: italic; }';

    styles += '  div.flex { display: flex; }';
    styles += '  div.f1 { flex: 1; }';
    styles += '  div.rb { width: 50%; font-size: 8pt; margin-top: 5pt; margin-bottom: 5pt; border-top: 1px solid black; }';

    styles += '  div.textB{ width: 100%; text-align: center; font-weight: bold; font-size: 10pt; }';

    styles += '  div.date { width: 30%; border-bottom: 1px solid #dddddd; margin: 3pt; text-align: center; font-size: 8pt;}';
    styles += '  div.date_text { width: 30%; text-align: center; font-size: 7pt; font-weight: normal; }';

    styles += '  .l { font-size: 7pt;}';
    styles += '  span.b { font-weight: bold;}';

    styles += '  div.drug { font-size: 12pt; font-weight: bold; width: 100%; border-bottom: 1px solid #888888; margin-top: 2pt; text-align: center;}';
    styles += '  div.drug_text { font-size: 8pt; font-weight: normal; width: 100%; text-align: center;}';

    styles += '  div.w30 { width: 30%;}';    


    styles += '  div.creck { width: 100%; border-bottom: 1px dashed #dddddd; margin: 10pt; }';

    styles += ' .next_page { page-break-after: always;}';
    

    styles += '</style>';
    return styles;
}

function convertDate(dt)
{
    return dt.substr(8, 2) + '.' + dt.substr(5, 2) + '.' + dt.substr(0, 4);
}

function getDefaultAddress(b)
{
    if(b) return "Челябинская область, г.Снежинск";
    else return "";
}
function getAnimalSex(b)
{
    switch(b)
    {
        case '1': return "М";
        case '2': return "Ж";
        case '3': return "М+Ж";
    }
}

export default getHTMLRecipe;

