/*

    Верхнее меню
*/

import { NavLink } from 'react-router-dom';


import './TopMenu.css';
import React from 'react';
import axios_sender from '../modules/axios_sender';
import isAllow from '../modules/rights.js';


function TopMenu(props) {
 

//All, drug_reduct, recipe, list_reduct, user_reduct
  function IsBlank ()  {
    if(isAllow("recipe")) return <NavLink className="col" to="/blank">Бланк рецепта</NavLink>
    return "";
  };
  function IsRecipes()
  {
    if(isAllow("recipe")) return <NavLink className="col" to="/recipes">Рецепты</NavLink>
    return "";
  }
  function IsLists()
  {
    if(isAllow("list_reduct")) return <NavLink className="col" to="/lists">Списки</NavLink>
    return "";
  }
  function IsDrugList()
  {
    if(isAllow("drug_reduct")) return <NavLink className="col" to="/drug_list">Список препаратов</NavLink>
    return "";
  }

  function IsUserReuct()
  {
    if(isAllow("user_reduct")) return <NavLink className="col" to="/user_reduct">Список пользователей</NavLink>
    return "";
  }

  return (
    <div className="TopMenu row">      
      { IsBlank() }
      { IsRecipes() }
      { IsLists() }
      { IsDrugList() }
      { IsUserReuct() }
      <div className="col-1"></div>
      
      { localStorage.getItem('id_user')>0 &&
      
        <div className="col-2">
          <span className="UserName"> { localStorage.getItem('user_name') } </span>
          <button className="UserNameExit" onClick={ ExitUser } title="Выход"></button>          
        </div>
      }
            
    </div>    
  );
/*
  <NavLink className="col" to="/blank">Бланк рецепта</NavLink>
  <NavLink className="col" to="/recipes">Рецепты</NavLink>
  <NavLink className="col" to="/lists">Списки</NavLink>
  <NavLink className="col" to="/drug_list">Список препаратов</NavLink>
*/


  function ExitUser()
  {    
    props.onOutAuthorize(false);
    axios_sender({form: 'exit', }, ExitUserSuccess);
    localStorage.clear('id_user');
    localStorage.clear('user_name');
    localStorage.clear('token');

  }
  function ExitUserSuccess(){}

}


export default TopMenu;
