/*
  Списки
*/

import React , { useState, useEffect } from 'react';
import './DrugList.css';

import axios_sender from '../modules/axios_sender.js'
import filter_list from '../modules/filter.js';
import MySelect from '../elements/MySelect.js';
import fill_list_array from '../modules/load_list.js';
import log_array from '../modules/log_array.js';


var ValuesArray = [];   // список препаратов
var is_drug_names_reduct = false;
var selected_drug = -1;


function DrugList() {
    

  const [table_drugs, SetTableDrugs] = useState();            // списки препаратов
  const [table_substance, SetTableSubstance] = useState();    // списки веществ

  const [table_dosage, SetTableDosage] = useState();          // список дозировок
  const [table_effect_dose, SetTableEffectDose] = useState(); // список разовых доз
  const [selected_dosage, SetSelectedDosage] = useState({value: 0, label: ""});          // выбранная дозировка
  const [selected_effect_dose, SetSelectedEffectDose] = useState({value: 0, label: ""});          // выбранная разовая доза



  const [newValue, setNewValue] = useState('');               // новый препарат
  const [selectedDrug, setSelectedDrug] = useState('---');    // выбранный препарат

  // список РУ в препарате и массив
  const [drugRUList, setdrugRUList] = useState(); 
  var drug_ru_list = [];

  const [drug_reg_name, setDrugRegName] = useState('');   // название нового регистрационного удостоверения
  const [drug_reg_date, setDrugRegDate] = useState('');   // дата нового регистрационного удостоверения

  const [list_dosage, setListDosage] = useState([]);      // список дозировок для текущего препарата для отображения
  //var m_list_dosage = [];                                 // и сам массив
  const [list_effect_dose, setListEffectDose] = useState([]);  // список разовых доз для текущего препарата для отображения
  //var m_list_effect_dose = [];                                 // и сам массив


  //const [isFormAddDrugReg, setIsFormAddDrugReg] = useState(false);   // активировать ли форму для добавления нового регистрационного удостоверения

  

  // загрузка препаратов из БД
  useEffect(() => {
    LoadDrugs();


    fill_list_array('dosage', (arr)=> { SetTableDosage(arr); });    // заполним список дозировками
    fill_list_array('dose_effect', (arr)=> { SetTableEffectDose(arr); });    // заполним список разовыми дозами


    //LoadDosages();    // загрузим список дозировок
  }, []);
  
  return (
    <div className="container-fluid row">
      

      <div className="col-4 border border-1 rounded m-2 ListDrug">
        <label>Список препаратов</label>

        <div className="input-group mb-1">        
          <input className="form-check-input-sm" type="checkbox" value="ok" id="drug_style" onChange={ChangeDrugStyle} />
          <strong>Редактировать</strong>
        </div>

        <div className="input-group mb-3">          
          <input type="text" className="form-control" placeholder="Новый препарат" value={ newValue } onChange={(event) => setNewValue(event.target.value)} onKeyUp={ReplaceDrugValue} />
          <button className="btn btn-sm btn-outline-secondary" type="button" onClick={ AddNewDrug }>+</button>
        </div>

        <div className="TableValue DrugList">          
          { table_drugs }
        </div>
      </div>      


      <div className="col-7 border border-1 rounded m-2 ListDrug">
        <div><label>{selectedDrug}</label></div>
        <div><label className="Litlle">Регистрационные удостоверения препарата</label></div>
        <div className="row form_drug_reg" >
          <div className="col-1"><span>№</span></div>
          <div className="col-7"><input className="form-control form-control-sm" value={drug_reg_name} onChange={(e) => setDrugRegName(e.target.value)} /></div>
          <div className="col-2"><input type="date" className="form-control form-control-sm" value={drug_reg_date} onChange={(e) => setDrugRegDate(e.target.value)} /></div>
          <div className="col-2"><button className="btn btn-sm btn-outline-secondary" type="button" onClick={ AddNewRegDrug }>Добавить РУ</button></div>
        </div>
        <div className="TableValue DrurRegsList h-10 bborder row" height="100px">
          { drugRUList }
        </div>

        <label className="Litlle">Дозировки препарата</label>
        <div className="TableValue bborder row">
          <div className="col-2">
            <MySelect cnInput="form-control form-control-sm myselect"
              options={ table_dosage }
              value={ selected_dosage }
              onChange={ (e) => SetSelectedDosage(e)  }
            />
          </div>
          <div className="col-1">
            <button className="btn-myselect" type="button" onClick={ AddDosage }>+</button>
          </div>  
          <div className="col-9">{ list_dosage }</div>
        </div>


        <label className="Litlle">Разовые дозы препарата</label>
        <div className="TableValue bborder row">
          <div className="col-2">
            <MySelect cnInput="form-control form-control-sm myselect"
              options={ table_effect_dose }
              value={ selected_effect_dose }
              onChange={ (e) => SetSelectedEffectDose(e)  }
            />
          </div>
          <div className="col-1">
            <button className="btn-myselect" type="button" onClick={ AddEffectDose }>+</button>
          </div>  
          <div className="col-9">{ list_effect_dose }</div>
        </div>        


        <label className="Litlle">Список веществ в препарате</label>        
        <div className="TableValue bborder">
          <ul className="list-group"> { table_substance } </ul>  
        </div>

      </div>

        
    </div> 
  );



//#region загрузка препаратов из БД  
  // загрузка препаратов из БД
  async function LoadDrugs()
  {
    axios_sender({form: 'get_drugs'}, LoadDrugsSuccess);
  } 
  function LoadDrugsSuccess(data)
  {
    ValuesArray = [];
    for(var i in data)  ValuesArray.push({ id: data[i].ID, name: data[i].name});
    // обновим список
    ReplaceDrugValue();
  }
//#endregion


//#region изменить стиль списка
  function ChangeDrugStyle()
  {
    let c = document.getElementById('drug_style').checked;
    if(c) is_drug_names_reduct = true;
    else is_drug_names_reduct = false;
    ReplaceDrugValue();
  }
//#region 


//#region добавление нового препарата
  // добавление нового препарата
  async function AddNewDrug()
  {    
    axios_sender({form: 'add_drug', drug_name: newValue}, AddNewDrugSuccess);
  }
  function AddNewDrugSuccess(data)
  {
    if(data.length != 0)
    {
        // заполним список полученный от БД
        ValuesArray.push({id:data.ID, name: data.name});
        // отправим этот список сформировать
        ReplaceDrugValue();  

        // очистим поле ввода
        setNewValue('');
    }  
  }
//#endregion


//#region Обновляет список препаратов
  // обновление списка препаратов
  function ReplaceDrugValue(select_id=null)
  {
    let li = [];

    // может пользователь набирает в строке поиска данные. Отфильтруем список тогда
    let res_array = [];
    if(newValue != "") res_array = filter_list(ValuesArray, newValue);
    else res_array = ValuesArray;

    for(var i in res_array)
    {     
      let s = res_array[i];
      let rowid = "rowid" + s.id;
      let key = "key_" + s.id;
      let className = "border border-1 rounded mb-1 DrugNoReduct";
      if(s.id == select_id) className += " selected";

      let drug_string = '';
      if(is_drug_names_reduct) drug_string =         
          <div className="input-group tableValue">
            <input type="text" className="form-control" defaultValue={s.name} id={rowid} onKeyUp={e => e.key === 'Enter' && RedactDrug(s.id, rowid)} />
            <button className="btn btn-outline-secondary" type="button" onClick={ (event) => DeleteDrug(s.id, event) }>X</button>
          </div>         
      else drug_string =
      <div className={className} onClick={() => SelectDrug(s.id, s.name)}>
        {s.name}
      </div> 

      li.push(
        <div key={key}>
        {drug_string}
        </div>

      );     
    }              
    SetTableDrugs(li);
  }
//#endregion


//#region  Функция удаления строки с препаратом
  // пользователь удаляет препарат из списка
  async function DeleteDrug(id)
  {
    axios_sender({form: 'delete_drug', id: id,}, DeleteDrugSuccess, id);
  }
  function DeleteDrugSuccess(data, id)
  {
    if(data == null) 
    {                           
      let index = -1;
      for(let i = 0; i < ValuesArray.length; i++)
        if(ValuesArray[i].id == id)
        {
          index = i;                            
          break;
        }                
      if(index >= 0) ValuesArray.splice(index, 1);

      // отправим этот список сформировать
      ReplaceDrugValue();  
      }
  }
//#endregion

//#region Пользователь нажимает кнопку на строке из списка (редактирует значение лекарства)
async function RedactDrug(id, rowid)
{
  var val = document.getElementById(rowid).value;  
  axios_sender({form: 'reduct_drug', id: id, val: val}, RedactDrugSuccess);  
}

function RedactDrugSuccess(data)
{
  // подправим список, полученный от БД
  for(var i in ValuesArray)
    if(ValuesArray[i].id == data.id)
    {
      ValuesArray[i].name = data.name;
      break;
    }
  // отправим этот список сформировать
  ReplaceDrugValue();
}
//#endregion

//#region Пользователь кликнул в препарат

  function SelectDrug(id, name)
  {
    //setNewValue("");        // очистим строку поиска   
    selected_drug = id;
    ReplaceDrugValue(id);   // выделим строку с препаратом
    setSelectedDrug(name);  // напишем название препарата
    FillSubstanceList(id);  // заполним препараты
    FillRegs(id);           // заполним регистрационные удостоверения
    FillDosages(id);        // заполним дозировки
    FillEffectDoses(id);    // заполним разовые дозы 
  }

//#endregion


//#region Заполнить список веществ

  // id - ID препарата
  function FillSubstanceList(id)
  {
    axios_sender({form: 'get_substance', id: id}, FilSubstanceListSuccess);
  }
  function FilSubstanceListSuccess(data)
  {

  }  
//#endregion




//  РЕГИСТРАЦИОННЫЕ УДОСТОВЕРЕНИЯ

//#region   Заполнить регистрационные удостоверения
  function FillRegs(id_drug)
  {
    axios_sender({form: 'get_drug_reg', id_drug: id_drug}, FillRegsSuccess);
  }
  function FillRegsSuccess(data)
  {
    drug_ru_list = [];
    for(var i in data)
    {      
      let s = data[i];
      drug_ru_list.push({ id: s.ID, name: s.name, date: s.date});
    }
    RefreshRegs();
  }
  // обновить список в соответствии с массивом
  function RefreshRegs()
  {
    let res = [];
    for(var i in drug_ru_list)
    {
      let s = drug_ru_list[i];
      let key = "reg_id_" + s.id;

      let cl_date = "DateSuccess";
      let date_drug = new Date(s.date);
      if(Date.now() > date_drug) cl_date = "DateNoSuccess";
      cl_date = "col-3 RegDrugOne border border-1 rounded " + cl_date;

      res. push(        
          <div className={cl_date} key={key}>
            <div className="row">
              <div className="col-9">
                <div>{s.name}</div>
                <div>[{s.date}]</div>
              </div>
              <div className="col-3">
                <button className="DeleteReg" title="Удалить РУ" onClick={ () => DeleteRu(s.id)}></button>
              </div>
            </div>
          </div>
      );
    }

    setdrugRUList(res);
  }

  // удалить РУ из препарата
  function DeleteRu(id)
  {    
    if(window.confirm("Будет удалено РУ для препарата. Уверены?"))
    {      
      axios_sender({form: 'delete_drug_reg', id_reg: id}, () => RefreshRegs());
      for(var i in drug_ru_list) if(drug_ru_list[i].id == id)
      {
        delete drug_ru_list[i];
        break;
      }      
    }
  }


  // добавить регистрационное удостоверение
  function AddNewRegDrug()
  {
    if(selected_drug <= 0)
    {
      alert("Препарат не выбран.");
      return;
    } 
    axios_sender({form: 'add_drug_reg', id_drug: selected_drug, name: drug_reg_name, date: drug_reg_date}, FillRegsSuccess);
  }

//#endregion

  
// #region  Дозировки для препарата
function FillDosages()
{
  axios_sender({form: 'get_mp_dosage', id_drug: selected_drug}, DosageRefresh);
}
function DosageRefresh(data)
{
  let arr = [];
  for(var i in data)
  {
    let s = data[i];
    arr.push(
      <span className="mp_dosage" key={crypto.randomUUID()}>
        {s.name}&nbsp;
        <button title="Удалить дозировку" onClick={ () => DeleteDosage(s.ID_dosage) }>X</button>
      </span>
    );
  }
  setListDosage(arr);
}
// добавить дозировку для препарата
function AddDosage()
{
  if(selected_drug <= 0)
    {
      alert("Препарат не выбран.");
      return;
    } 
  axios_sender({form: 'add_mp_dosage', id_drug: selected_drug, id_dosage: selected_dosage.value}, () => FillDosages(selected_drug));
}
// удалить дозировку у препарата
function DeleteDosage(id_dosage)
{
  
  axios_sender({form: 'delete_mp_dosage', id_drug: selected_drug, id_dosage: id_dosage}, () => FillDosages(selected_drug));
}

//#endregion

//#region   Разовые дозы для препарата

function FillEffectDoses()
{
  axios_sender({form: 'get_mp_effect_dose', id_drug: selected_drug}, EffectDoseRefresh);
}
function EffectDoseRefresh(data)
{
  let arr = [];
  for(var i in data)
  {
    let s = data[i];
    arr.push(
      <span className="mp_dosage" key={crypto.randomUUID()}>
        {s.name}&nbsp;
        <button title="Удалить разовую дозу" onClick={ () => DeleteEffectDose(s.ID_dose_effect) }>X</button>
      </span>
    );
  }
  setListEffectDose(arr);
}
// добавить дозировку для препарата
function AddEffectDose()
{  
  if(selected_drug <= 0)
    {
      alert("Препарат не выбран.");
      return;
    } 
  axios_sender({form: 'add_mp_effect_dose', id_drug: selected_drug, id_effect_dose: selected_effect_dose.value}, () => FillEffectDoses(selected_drug));
}
// удалить дозировку у препарата
function DeleteEffectDose(id_effect_dose)
{
  
  axios_sender({form: 'delete_mp_effect_dose', id_drug: selected_drug, id_effect_dose: id_effect_dose}, () => FillEffectDoses(selected_drug));
}
//#endregion

}

export default DrugList;
