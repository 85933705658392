/*
  Список рецептов
*/

import React, {useState, useEffect} from 'react';
import axios_sender from '../modules/axios_sender';


import './RecipeList.css';
import OneRecipe from './OneRecipe/OneRecipe.js'


var recipes_list = [];    // массив рецептов

function RecipeList() {

  const [recipe_list, setRecipeList] = useState(<strong>Список рецептов пуст...</strong>);



  // чекбоксы для фильтров
  const [check_no_repay, SetCheckNoRepay] = useState(true);
  const [check_repay, SetCheckRepay] = useState(false);
  const [recipe_filter, SetRecipeFilter] = useState("");

  const[recipe_find_count, SetRecipeFindCount] = useState(0);       // количество рецептов всего в выдаче
  const[recipe_navigator, SetRecipeNavigator] = useState(0);        // отображение страниц перехода
  //const[page_num, SetPageNum] = useState(0);                        // текущий номер страницы
  var page_num = 1;
  const[in_page, SetInPage] = useState(20);


  // загрузка рецептов из БД
  useEffect(() => { LoadRecipes(); }, []);  

  useEffect(() => { LoadRecipes(); }, [check_no_repay, check_repay, recipe_filter, in_page]);  

  return (
    <div className="container row ReceipeList">
      <div className="col-4">
          <label className="border border-1 rounded">Фильтры для рецептов</label>
          <div className="FiltersRecipe">
              <input type="checkbox" checked={ check_no_repay } onChange={(e) => SetCheckNoRepay(e.target.checked)}/> <span>Непогашенные рецепты</span>              
          </div>
          <div className="FiltersRecipe">
              <input type="checkbox" checked={ check_repay } onChange={(e) => SetCheckRepay(e.target.checked)}/> <span>Погашенные рецепты</span>
          </div>
          <span className="filterCapt">Фильтр</span>
          <div className="FiltersRecipe row">
            <div className="col-10">
              <input className="form-control" type="text" value={ recipe_filter } onChange={ (e) => SetRecipeFilter(e.target.value) }/>
            </div>
            <div className="col-2">
              <button className="btn btn-outline-secondary" type="button" title="Очистить фильтр" onClick={ () => SetRecipeFilter("")}>X</button>
            </div>            
          </div>  


          <div className="FiltersRecipe input-group mb-3">
                <span className="input-group-text">показывать по:  </span>
                <select className="form-control"
                    value={ in_page }
                    onChange={ (e) => SetInPage(e.target.value) }
                >
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>100</option>
                </select>
            </div>          

      </div>
      <div className="col-8">
          <label className="border border-1 rounded">Список рецептов</label>
        <div className="recipesNavigator">
            Всего: <span className="RecipeFindCount">{ recipe_find_count }</span>&nbsp;
            <span>{ recipe_navigator }</span>
        </div>

        <div>{ recipe_list }</div>

      </div>
    </div> 
  );

 
  //#region функция загрузки рецептов из БД

  function LoadRecipes()
  {    
    axios_sender({form: 'get_recipes', filter: recipe_filter, check_no_repay: check_no_repay, check_repay: check_repay, begin_num: page_num, limit: in_page}, LoadRecipesSuccess);
  }
  function LoadRecipesSuccess(data)
  {
    let qty = data.qty;
    SetRecipeFindCount(qty);
    setRecipesNav(qty, 0);
    

    let datas = data.data;    

    recipes_list = [];
    for(let i in datas)
    {
      let s = datas[i];

      let animal_sex = "М";
      if(s.animal_sex == '2') animal_sex = "Ж";
      else if(s.animal_sex == '3') animal_sex = "М+Ж";

      let rl = {
        id: s.ID,
        doctor: s.doctor,
        animal_type: s.animal_type,
        animal_sex: animal_sex,
        animal_age: s.animal_age,
        animal_weight: s.animal_weight,
        animal_name: s.animal_name,
        client_name1: s.client_name1,
        client_name2: s.client_name2,
        client_name3: s.client_name3,
        client_address: s.client_address,
        client_subject: s.client_subject,
        recipe_datetime: s.datetime,
      };

      recipes_list.push(rl);
    }

    RefreshRecipeList();
  }
  //#endregion

  //#region функция обновления списка рецептов
  function RefreshRecipeList()
  {
    let res = [];
    for(var i in recipes_list)
    {
      let s = recipes_list[i];
      let key = "rec_id_" + s.id;
      
      let row = ( <OneRecipe key={key} recipe_info={s} />
      );
      res.push(row);
    }
    setRecipeList(res);
  }
  //#endregion

  // функция составляет ссылки на спискирецептов для навигации
  function setRecipesNav(qty, num=0)
  {
    let res = [];
    //let in_page = 3;             // рецептов на одной странице
    
    let pg_qty = Math.ceil(qty / in_page);

    for(let i=0; i<pg_qty; i++)
    {
      let begin = i * in_page + 1;
      let end = (i+1) * in_page;
      if(end > qty) end = qty;
      let key = "page_navigator_" + i;

      let cn = "PageNavigator";
      if(begin == page_num) cn += " PageNavigatorSelected";

      res.push (
        <span className={ cn } key={ key } onClick={ () => { page_num = begin; LoadRecipes(); } }>[{ begin } - { end }]</span>
      );
    }
    
    SetRecipeNavigator(res);
  }

  function NavigateRecipe(begin)
  {

  }


}

export default RecipeList;


