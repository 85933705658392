'use strict';

import axios_sender from './axios_sender.js'

// Загрузить список из БД и вернуть массив
function fill_list_array(table_name, func)
{    
    axios_sender({form: 'get_list', table_name: table_name}, successFill, {func: func});
}
function successFill(data, obj)
{
    var res = [];
    for(var i in data)
    {    
        let key = crypto.randomUUID();
        res.push({key:key, value: data[i].ID, label: data[i].name});
    }
    if(obj.func !== undefined)  obj.func(res);
}


export default fill_list_array;